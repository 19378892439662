import styled from 'styled-components'

const Head = styled.div`
    min-height: 45rem;
  `

Head.container = styled.div`
  padding: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;  
`

Head.header1 = styled.h1`
  font-size: 9rem;
  font-weight: 100;
  color: ${(props) => props.theme.palette.black.light};
  padding: 60px;
  text-align: left;
  border-bottom: 1px solid ${(props) => props.theme.palette.text.secondary};
`

Head.header2 = styled.h2`
  padding: 20px;
  padding-bottom: 0px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

Head.section = styled.div`
  margin: 0 auto;
  text-align: center;
`

export default Head
